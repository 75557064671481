<template>
  <div class="page">
    <!-- 页面头部 包含面包屑 -->
    <PageHead
      :headImg="exampleImg"
      :subTitle="subTitle"
      :subTitleEn="subTitleEn"
    />

    <div class="page-body">
      <div class="card-detail">
        <div class="detail-head flex align-end">
          <img src="images/detail-leftTop-bg.png" class="leftTop-bg" />
          <div class="head-name">{{ bodyHeadLebal.title }}</div>
          <div class="head-name-en">{{ bodyHeadLebal.subTitle }}</div>
        </div>
        <div class="detail-body">
          <div class="html-info text-center">
            <span>{{ scenicSpot_detail.title }}</span>
          </div>
          <div
            class="shuffling flex justify-center"
            v-if="scenicSpot_detail.pics && scenicSpot_detail.pics.length > 0"
          >
            <div class="swiper">
              <swiper
                :slides-per-view="1"
                :space-between="0"
                :navigation="{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }"
                :pagination="{ type: 'fraction', el: '.my-swiper-pagination' }"
              >
                <swiper-slide
                  v-for="(pic, index) in scenicSpot_detail.pics"
                  :key="index"
                >
                  <a-image
                    width="100%"
                    :src="pic"
                    :preview="false"
                    class="img-fit"
                  />
                </swiper-slide>
                <div class="my-swiper-pagination"></div>
              </swiper>
              <div class="swiper-button-prev swiper-button"></div>
              <div class="swiper-button-next swiper-button"></div>
            </div>
          </div>
          <!-- <div class="center-container flex justify-around">
            <div class="center-box flex justify-center">
              <div style="width:58px">
                <PictureSquare src="icons/icon-detail-phone.png"/>
              </div>
              <div class="right flex flex-direction" style="flex:1">
                <div>电话</div>
                <div style="color:#aaa">{{scenicSpot_detail.telephone||'暂无电话'}}</div>
              </div>
            </div>
            <div class="center-box flex justify-center">
              <div style="width:58px">
                <PictureSquare src="icons/icon-detail-map.png"/>
              </div>
              <div class="right flex flex-direction" style="flex:1">
                <div>地址</div>
                <div style="color:#aaa">{{scenicSpot_detail.address||'暂无地址'}}</div>
              </div>
              {{scenicSpot_detail.address||'暂无地址'}}
            </div>
            <div class="center-box flex justify-center">
              <div style="width:58px">
                <PictureSquare src="icons/icon-detail-price.png"/>
              </div>
              <div class="right flex flex-direction" style="flex:1">
                <div>价格</div>
                <div style="color:#aaa">{{scenicSpot_detail.price||'暂无价格'}}</div>
              </div>
            </div>
          </div> -->
          <div class="html-des-label">{{ DetailSetting.description }}</div>
          <div v-html="scenicSpot_detail.content" class="html-content"></div>
          <!-- <Map :map_data="map_data"/> -->
        </div>
        <audio
          ref="audio"
          v-if="audio"
          :src="audio"
          v-show="false"
          controls="controls"
        ></audio>

        <div v-if="audio" class="audio flex align-center justify-center">
          <div class="hover-hand" style="height: 50%" @click="audioClick">
            <img :src="'icons/' + audio_icon + '.png'" style="height: 100%" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHead from "@/components/page-header/page-head";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/pagination/pagination.scss";
SwiperCore.use([Pagination, Navigation, Autoplay]);
import PictureSquare from "@/components/browse/picture-square";
import { mapState } from "vuex";
import Map from "@/components/bmap/bmap";
export default {
  name: "scenicDetail",
  components: {
    PageHead,
    Swiper,
    SwiperSlide,
    PictureSquare,
    Map,
  },
  computed: {
    ...mapState({
      settingLang: (state) => state.lang,
      bread: (state) => state.bread,
      DetailSetting: (state) => state.langSetting.DetailSetting,
    }),
    bodyHeadLebal() {
      let bread = this.bread;
      let bodyHeadLebal = {
        title: "详情",
        subTitle: "Detail",
      };
      if (bread && bread.length > 2) {
        bodyHeadLebal.title = this.bread[2][this.settingLang];
        bodyHeadLebal.subTitle =
          this.settingLang === "en" ? "" : this.bread[2].en;
      }
      return bodyHeadLebal;
    },
  },
  created() {
    this.initData(this.$route.query.id);
  },
  data() {
    return {
      title: "攻略详情",
      titleEn: "Raider Detail",
      exampleImg: process.env.BASE_URL + "images/page-head/raiders.png",
      subTitle: "八桂神韵 绿色乐意",
      subTitleEn: "八桂神韵 绿色乐意",
      pageParams: {},
      scenicSpot_detail: {},
      map_data: {},
      audio_icon: "play",
      audio: "",
    };
  },
  methods: {
    async initData(id) {
      this.scenicSpot_detail = await this.$http.ScenicSpot_read({
        id: id,
      });
      this.scenicSpot_detail.pics =
        this.scenicSpot_detail.scenic_spot_files.map((item) => {
          if (item.type === "image") {
            return item.url;
          } else {
            return null;
          }
        });
      this.scenicSpot_detail.pics = this.scenicSpot_detail.pics.filter(
        (item) => item
      );
      this.scenicSpot_detail.title = this.scenicSpot_detail.spot_name;
      let hasAudio = this.scenicSpot_detail.scenic_spot_files.find(
        (item) => item.type === "audio"
      );
      if (hasAudio) {
        this.audio = this.hasAudio.url;
      }
    },
    audioClick() {
      if (this.audio_icon === "play") {
        this.audio_icon = "pause";
        this.$refs.audio.play();
      } else {
        this.audio_icon = "play";
        this.$refs.audio.pause();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  font-weight: 400;
  background: #f5f5f5;
}
.page-body {
  padding: 2.5rem 18.75%;
  .card-detail {
    position: relative;
    .audio {
      position: absolute;
      z-index: 2;
      bottom: 1.5rem;
      right: 1rem;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      background: #22915b;
    }
  }
  .detail-head {
    // height: 120px;
    padding-bottom: 1rem;
    border-bottom: 1px solid #aaaaaa;
    margin-bottom: 1rem;
    position: relative;
    .leftTop-bg {
      position: absolute;
      left: 5%;
      bottom: 0;
      height: 150%;
    }
    .head-name {
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #212121;
      margin-right: 0.6rem;
    }
    .head-name-en {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }
  }
  .detail-body {
    background: #fff;
    padding: 1rem 2%;
    .html-info {
      padding: 0.5rem 0;
      background: #f5f5f5;
      font-size: 1.8rem;
      font-family: Microsoft YaHei;
      color: #212121;
    }
    .html-des-label {
      padding: 1rem 0 0.5rem 0;
      font-size: 1.5rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #212121;
    }
    .html-content {
      // min-height: 300px;
      padding-bottom: 1rem;
      /deep/img {
        max-width: 100%;
      }
    }
    .center-container {
      margin-top: 0.8rem;
      border: 6px solid #eeeeee;
      padding: 2.5rem 5%;

      .center-box {
        // flex: 1;
        padding: 0 1%;
        .right {
          font-size: 0.9rem;
          font-family: Microsoft YaHei;
          color: #666666;
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.shuffling {
  // height: 816px;
  width: 100%;
  max-height: 433px;
  position: relative;
}
.swiper {
  width: 100%;

  & /deep/ .swiper-container {
    width: 100%;
    height: 100%;

    .swiper-slide {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }

    .swiper-pagination {
      .swiper-pagination-bullet-active {
        background-color: #f29b76;
      }
    }
  }

  .swiper-button {
    width: 45px;
    height: 45px;
    background: rgba(27, 27, 27, 0.7);
    border-radius: 50%;
    margin-left: 1.4rem;
    margin-right: 1.4rem;
    color: #fff;
    // margin-top: -2rem;
    z-index: 3;
    padding: 10px 0;
  }
  .swiper-button-prev:after {
    content: "prev";
    font-size: 20px;
  }
  .swiper-button-next:after {
    content: "next";
    font-size: 20px;
  }

  .my-swiper-pagination {
    // height: 70px;
    margin-top: -45px;
    position: relative;
    z-index: 3;
    padding: 0 0.5rem;
    color: #fff;
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
  }
}
</style>
